<script>
import { PortableText } from "@portabletext/svelte";
import { onMount } from "svelte";

export let section;

let formPromise;
let succesfullySubmitted;
let container;
let form;

async function subscribeNewsletter(event) {
  if (!checkIfFormIsValid(event)) {
    return false;
  }

  const formData = new FormData(event.target);

  const data = {};

  for (let field of formData) {
    const [key, value] = field;
    data[key] = value;
  }

  data.newsletter = true;

  const response = await fetch("/api/subscribe-newsletter", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      formData: data,
    }),
  });

  let res = await response.json();

  if (res.statusCode == 202) {
    succesfullySubmitted = true;
    return true;
  } else {
    throw new Error("error");
  }
}

function checkIfFormIsValid(pEvent) {
  if (pEvent.target.dataset.valid) {
    return true;
  } else {
    return false;
  }
}

onMount(() => {
  function setUpFormValidation(pForm) {
    let form = pForm;

    const validation = {
      formIsValid: function () {
        let isValid = true;

        form.querySelectorAll("input-wrapper").forEach((elem) => {
          if (elem.classList.contains("not-valid")) {
            isValid = false;
          }
        });

        form.querySelectorAll("[required]").forEach((elem) => {
          if (elem.value <= 0) {
            isValid = false;
          }

          if (elem.type == "checkbox" && elem.checked == false) {
            isValid = false;
          }
        });

        return isValid;
      },
      isEmailAddress: function (pString) {
        const pattern = /[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]{2,3}/;
        return pattern.test(pString);
      },
      isPhoneNumber: function (pString) {
        const pattern = /^\d+\.?\d*$/;
        const length = 10;
        if (pString.length == length && pattern.test(pString)) {
          return true;
        } else {
          return false;
        }
      },
      hasLength: function (pString) {
        return pString.length > 5 ? true : false;
      },
    };

    function checkInput(pElem) {
      let elem = pElem;

      function toggleElemClass(pElem, pIsValid) {
        if (pIsValid) {
          pElem.closest(".input-wrapper").classList.remove("not-valid");
          pElem.closest(".input-wrapper").classList.add("is-valid");
        } else {
          pElem.closest(".input-wrapper").classList.add("not-valid");
          pElem.closest(".input-wrapper").classList.remove("is-valid");

          if (elem.value.length <= 0) {
            pElem.closest(".input-wrapper").classList.remove("is-active");
          }
        }
      }

      if (elem.type == "email") {
        if (
          validation.hasLength(elem.value) &&
          validation.isEmailAddress(elem.value)
        ) {
          toggleElemClass(elem, true);
        } else {
          toggleElemClass(elem);
        }
      }

      if (elem.type == "text") {
        if (validation.hasLength(elem.value)) {
          toggleElemClass(elem, true);
        } else {
          toggleElemClass(elem);
        }
      }

      if (elem.type == "tel") {
        if (validation.isPhoneNumber(elem.value)) {
          toggleElemClass(elem, true);
        } else {
          toggleElemClass(elem);
        }
      }

      if (elem.type == "textarea") {
        if (validation.hasLength(elem.value)) {
          toggleElemClass(elem, true);
        } else {
          toggleElemClass(elem);
        }
      }

      form.dataset.valid = validation.formIsValid();
    }

    // Prevents users from typing letters in "tel" input field
    form.querySelectorAll("input").forEach((elem) => {
      elem.addEventListener(
        "blur",
        () => {
          setTimeout(() => {
            checkInput(elem);
          }, 200);
        },
        false
      );

      elem.addEventListener(
        "focus",
        () => {
          elem.closest(".input-wrapper").classList.add("is-active");
        },
        false
      );
    });

    form.querySelectorAll("textarea").forEach((elem) => {
      elem.addEventListener(
        "blur",
        () => {
          checkInput(elem);
        },
        false
      );
    });
  }

  setUpFormValidation(form);
});
</script>

<section class="newsletter-section">
  <div class="container-fluid">
    <div class="row">
      <div class="col-sm-12">
        <div class="newsletter-section__inner">
          <div class="newsletter-section__copy">
            <h2 class="newsletter-section__copy__headline">
              {section.headline}
            </h2>
            <div class="newsletter-section__copy__body">
              <PortableText value={section.body} />
            </div>
          </div>

          <div class="newsletter-section__form-container">
            {#await formPromise then value}
              {#if value !== undefined}
                <div class="newsletter-section__form-alerts">
                  <alert class="newsletter-section__form__success is-visible">
                    {section.success_state.headline}
                  </alert>
                </div>
              {/if}
            {:catch error}
              <div class="newsletter-section__form-alerts">
                <alert class="newsletter-section__form__error is-visible">
                  There was an issue with the server when submitting your form.
                  Please refresh this page and try again.
                </alert>
              </div>
            {/await}

            <form
              class:is-hidden={succesfullySubmitted}
              bind:this={form}
              on:submit|preventDefault={(event) => {
                formPromise = subscribeNewsletter(event);
              }}
              class="newsletter-section__form"
              name="newsletter-signup"
              aria-label="Sign up for the Varo newsletter."
            >
              <div class="newsletter-section__form__inner">
                <div class="newsletter-section__form__section">
                  <div class="input-wrapper">
                    <label for="newsletter_email"> Email </label>
                    <input
                      type="email"
                      id="newsletter_email"
                      name="email"
                      required
                      class="input--primary input--medium"
                    />
                    <alert> Please enter a valid email address. </alert>
                  </div>
                  <div class="newsletter-section__form__submit">
                    <button
                      type="submit"
                      class="button--tertiary button--medium"
                    >
                      <span>Sign Up</span>
                    </button>
                  </div>
                </div>

                <div class="newsletter-section__form__section">
                  <div class="newsletter-section__form__disclaimer">
                    <div class="disclaimer-acceptance">
                      <input
                        type="checkbox"
                        name="gdpr-compliance"
                        id="gdpr-compliance"
                        required
                      />
                      <label class="disclaimer" for="gdpr-compliance">
                        {section.consent}
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<style>
@-webkit-keyframes show-modal {
  0% {
    transform: translateY(110%);
  }
  to {
    transform: translateY(0);
  }
}
@keyframes show-modal {
  0% {
    transform: translateY(110%);
  }
  to {
    transform: translateY(0);
  }
}
@-webkit-keyframes hide-modal {
  0% {
    transform: translateY(0);
  }
  to {
    transform: translateY(110%);
  }
}
@keyframes hide-modal {
  0% {
    transform: translateY(0);
  }
  to {
    transform: translateY(110%);
  }
}

.newsletter-modal {
  position: fixed;
  width: 100%;
  left: 0;
  right: 0;
  bottom: 0;
  background: #fff;
  z-index: 10;
  box-shadow: 2px 3px 14px -6px #000;
  transform: translateY(100%);
}

.newsletter-modal.is-open {
  -webkit-animation: show-modal 0.4s cubic-bezier(0.165, 0.84, 0.44, 1) 2s
    forwards;
  animation: show-modal 0.4s cubic-bezier(0.165, 0.84, 0.44, 1) 2s forwards;
}

.newsletter-modal.is-closed {
  -webkit-animation: hide-modal 0.3s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
  animation: hide-modal 0.3s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
}

.newsletter-modal .newsletter-modal__inner {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 1rem 0.75rem 0.375rem;
  max-width: 38rem;
  margin-right: auto;
  margin-left: auto;
}

@media only screen and (min-width: 768px) {
  .newsletter-modal__inner {
    flex-direction: row;
    max-width: 58rem;
    padding-left: 1.5rem;
    padding-right: 2.25rem;
    padding-top: 0.75rem;
  }
}

@media only screen and (min-width: 961px) {
  .newsletter-modal__inner {
    flex-direction: row;
    max-width: 64rem;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: 0.75rem;
    justify-content: space-evenly;
  }
}
@media only screen and (min-width: 768px) {
  .newsletter-modal__copy {
    max-width: 17rem;
    margin-right: 1rem;
  }
}
@media only screen and (min-width: 961px) {
  .newsletter-modal__copy {
    margin-right: 1.5rem;
  }
}
.newsletter-modal .newsletter-modal__copy .newsletter-modal__copy__headline {
  color: var(--primary-color);
  margin-bottom: 0.375rem;
  font-size: 1rem;
  line-height: var(--body-line-height);
}
.newsletter-modal .newsletter-modal__copy .newsletter-modal__copy__body {
  margin-bottom: 0.75rem;
  font-size: var(--text-micro);
  line-height: var(--body-line-height);
}
.newsletter-modal .newsletter-modal__close {
  position: relative;
  width: 1.875rem;
  display: block;
  top: 0.375rem;
  position: absolute;
  margin: auto;
  height: 1.15rem;
  width: 1.15rem;
  right: 0.75rem;
  background-color: transparent;
}
@media only screen and (min-width: 768px) {
  .newsletter-modal .newsletter-modal__close {
    height: 1rem;
    width: 1rem;
  }
}
@media only screen and (min-width: 961px) {
  .newsletter-modal .newsletter-modal__close {
    top: 0.75rem;
    right: 1.125rem;
  }
}
.newsletter-modal .newsletter-modal__close:after,
.newsletter-modal .newsletter-modal__close:before {
  content: " ";
  height: 3px;
  width: 100%;
  position: absolute;
  left: 0;
  right: 0;
  border-radius: 100px;
  background-color: #595757;
  transition: all 0.4s cubic-bezier(0.165, 0.84, 0.44, 1);
}
.newsletter-modal .newsletter-modal__close:after {
  transform: rotate(-45deg);
}
.newsletter-modal .newsletter-modal__close:before {
  transform: rotate(45deg);
}
.newsletter-modal .newsletter-modal__close:focus:after,
.newsletter-modal .newsletter-modal__close:focus:before,
.newsletter-modal .newsletter-modal__close:hover:after,
.newsletter-modal .newsletter-modal__close:hover:before {
  background-color: var(--primary-color);
}
.newsletter-modal .newsletter-modal__form {
  width: 100%;
  display: flex;
  flex-direction: column;
}
@media only screen and (min-width: 768px) {
  .newsletter-modal .newsletter-modal__form {
    width: 100%;
    display: flex;
    align-items: center;
  }
}
@media only screen and (min-width: 961px) {
  .newsletter-modal .newsletter-modal__form {
    max-width: 35rem;
  }
}
.newsletter-modal .newsletter-modal__form .newsletter-modal__form__inner {
  display: flex;
  align-items: center;
  width: 100%;
  flex-direction: column;
}
.newsletter-modal .newsletter-modal__form .newsletter-modal__form__errors {
  width: 100%;
  font-size: var(--text-micro);
  line-height: var(--body-line-height);
}
@media only screen and (min-width: 768px) {
  .newsletter-modal .newsletter-modal__form .newsletter-modal__form__errors {
    position: absolute;
    bottom: 0.75rem;
  }
}
.newsletter-modal .newsletter-modal__form .newsletter-modal__form__submit {
  min-width: 7rem;
  margin-left: 0.75rem;
}
@media only screen and (min-width: 961px) {
  .newsletter-modal .newsletter-modal__form .newsletter-modal__form__submit {
    min-width: 9rem;
  }
}
.newsletter-modal
  .newsletter-modal__form
  .newsletter-modal__form__submit
  button {
  margin-bottom: 0;
  width: 100%;
}
@media only screen and (min-width: 961px) {
  .newsletter-modal
    .newsletter-modal__form
    .newsletter-modal__form__disclaimer
    .disclaimer-acceptance {
    font-size: 0.7rem;
    line-height: var(--body-line-height);
  }
}
.newsletter-modal .newsletter-modal__form .newsletter-modal__form__section {
  width: 100%;
  display: flex;
  flex-direction: row;
  margin-bottom: 0.375rem;
}
.newsletter-modal
  .newsletter-modal__form
  .newsletter-modal__form__section
  input {
  margin-bottom: 0;
}
.newsletter-modal
  .newsletter-modal__form
  .newsletter-modal__form__section
  .input-wrapper {
  padding-top: 0;
  margin-bottom: 0;
  max-width: 22rem;
}
.newsletter-modal
  .newsletter-modal__form
  .newsletter-modal__form__section
  .input-wrapper.is-active
  label {
  opacity: 0;
}

.input-wrapper label {
  top: 0.75rem;
  left: 0.75rem;
  color: var(--primary-color);
  font-size: var(--text-micro);
}
.newsletter-modal .newsletter-modal__form .newsletter-modal__form__success {
  margin-bottom: 0;
  font-weight: normal;
  color: var(--primary-color);
}

.newsletter-section {
  background-color: var(--tertiary-color);
  padding-top: 2.25rem;
  padding-bottom: 2.25rem;
  margin-top: 1.875rem;
  margin-bottom: 1.875rem;
  width: 100%;
  border-top: 1px solid var(--quaternary-color);
  border-bottom: 1px solid var(--quaternary-color);
}

@media only screen and (min-width: 768px) {
  .newsletter-section {
    margin-top: 12vh;
  }
}

.newsletter-section .newsletter-section__copy {
  max-width: 23rem;
}

@media only screen and (min-width: 768px) {
  .newsletter-section .newsletter-section__copy {
    margin-right: 1.125rem;
  }
}

.newsletter-section
  .newsletter-section__copy
  .newsletter-section__copy__headline {
  color: var(--primary-color);
}

.newsletter-section .newsletter-section__inner {
  width: 100%;
  display: flex;
  flex-direction: column;
  max-width: 55ch;
  margin-left: auto;
  margin-right: auto;
}

@media only screen and (min-width: 768px) {
  .newsletter-section .newsletter-section__inner {
    flex-direction: row;
    max-width: 64rem;
  }
}

@media only screen and (min-width: 961px) {
  .newsletter-section .newsletter-section__inner {
    flex-direction: row;
    justify-content: space-evenly;
  }
}

.newsletter-section .newsletter-section__form {
  width: 100%;
  display: flex;
  flex-direction: column;
}

@media only screen and (min-width: 768px) {
  .newsletter-section .newsletter-section__form {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: row;
  }
}

@media only screen and (min-width: 961px) {
  .newsletter-section .newsletter-section__form {
    max-width: 35rem;
  }
}

.newsletter-section .newsletter-section__form .newsletter-section__form__inner {
  display: flex;
  align-items: center;
  width: 100%;
  flex-direction: column;
}

.newsletter-section
  .newsletter-section__form
  .newsletter-section__form__errors {
  width: 100%;
  font-size: var(--text-micro);
  line-height: var(--body-line-height);
}

@media only screen and (min-width: 768px) {
  .newsletter-section
    .newsletter-section__form
    .newsletter-section__form__errors {
    position: absolute;
    bottom: 0.75rem;
  }
}

.newsletter-section
  .newsletter-section__form
  .newsletter-section__form__submit {
  min-width: 7rem;
}

@media only screen and (min-width: 961px) {
  .newsletter-section
    .newsletter-section__form
    .newsletter-section__form__submit {
    min-width: 9rem;
    margin-left: 0.75rem;
  }
}

.newsletter-section
  .newsletter-section__form
  .newsletter-section__form__submit
  button {
  margin-bottom: 0;
  width: 100%;
}

@media only screen and (min-width: 961px) {
  .newsletter-section
    .newsletter-section__form
    .newsletter-section__form__disclaimer
    .disclaimer-acceptance {
    font-size: 0.7rem;
    line-height: var(--body-line-height);
  }
}

.newsletter-section
  .newsletter-section__form
  .newsletter-section__form__section {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 1.125rem;
}
@media only screen and (min-width: 768px) {
  .newsletter-section
    .newsletter-section__form
    .newsletter-section__form__section {
    flex-direction: row;
    margin-bottom: 0;
  }
}
.newsletter-section
  .newsletter-section__form
  .newsletter-section__form__section
  .input-wrapper {
  padding-top: 0;
  margin-bottom: 0;
  max-width: 100%;
}
.newsletter-section
  .newsletter-section__form
  .newsletter-section__form__section
  .input-wrapper.is-active
  label {
  opacity: 0;
}

.newsletter-section
  .newsletter-section__form
  .newsletter-section__form__success {
  margin-bottom: 0;
  font-weight: normal;
  color: var(--primary-color);
}

.disclaimer-acceptance label {
  cursor: pointer;
}
.disclaimer-acceptance {
  align-items: flex-start;
}

.disclaimer-acceptance input {
  margin-top: 0.25rem;
}

form.is-hidden {
  display: none;
}

.newsletter-section__form-container {
  width: 100%;
  margin-top: 0.5rem;
}

@media screen and (min-width: 768px) {
  .newsletter-section__form-container {
    margin-top: 0.5rem;
  }
}

.newsletter-section__form-alerts {
  display: flex;
  align-items: center;
  justify-content: center;
}
.newsletter-section__form__error {
  background-color: #b73d37;
  border-radius: 8px;
  padding: 0.75rem;
  height: auto;
  width: 100%;
  display: block;
  margin-bottom: 1.25rem;
  color: #fff;
  font-size: var(--text-micro);
  line-height: var(--body-line-height);
}

.newsletter-section__form__success {
  text-align: left;
  width: 100%;
}

@media screen and (min-width: 768px) {
  .newsletter-section__form__success {
    text-align: center;
  }
}
</style>
