<script>
import { PortableText } from '@portabletext/svelte';
export let section;
</script>

<section class="closing-statement-section">
  <div class="closing-statement-section__inner">
    <div class="container-fluid">
      <div class="row  center-md">
        <div class="col-sm-12 col-lg-11">
          <div class="closing-statement-section__copy">
            <h1 class="headline  closing-statement-section__headline">
              {section.headline}
            </h1>
            <div class="closing-statement-section__body">
              <PortableText value={section.body} />
            </div>
            <div class="closing-statement__cta">
              <a
                href="https://app.pogo-lt.org/"
                target="_blank"
                rel="external"
                title="Launch Pogo LT"
                class="button button--primary button--medium "
              >
                <span>Launch Pogo LT</span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="closing-statement__background ng-scope">
    <picture>
      <source
        media="(min-width: 2000px)"
        srcset="https://cdn.sanity.io/images/ebeyjpve/production/4b3de05ca73e2504176648b4a2f16ab184294aa0-1427x237.svg"
      />

      <source
        media="(min-width: 961px)"
        srcset="https://cdn.sanity.io/images/ebeyjpve/production/7274da842f1a18894b2302e01cbadfebd847727e-1112x237.svg"
      />
      <source
        media="(min-width: 768px)"
        srcset="https://cdn.sanity.io/images/ebeyjpve/production/e4dc8da0ff4ba7dbb0b2e99cbe4c4c549f6427e5-724x237.svg"
      />
      <img
        alt="A line art style illustration of various cold-chain fridges used in the market and are compatible with 30DTR reports."
        class=" lazyloaded"
        loading="lazy"
        width="100%"
        height="400"
        src="https://cdn.sanity.io/images/ebeyjpve/production/cd4d90075dd5a8cc0e6218915994c53f5cdb2585-414x150.svg"
      />
    </picture>
  </div>
</section>

<style>
.closing-statement-section {
  text-align: center;
  min-height: 43rem;
  position: relative;
  z-index: 0;
  overflow: hidden;
  padding-top: 4rem;
  margin-top: -4rem;
}
@media only screen and (min-width: 768px) {
  .closing-statement-section {
    overflow: hidden;
    padding-bottom: 0;
    margin-top: 0;
    min-height: 30rem;
  }
}
@media only screen and (min-width: 961px) {
  .closing-statement-section {
    min-height: 100vh;
    padding-top: 5rem;
  }
}
@media only screen and (min-width: 1681px) {
  .closing-statement-section {
  }
}
.closing-statement-section .closing-statement-section__inner {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 1;
}

.closing-statement-section .closing-statement-section__copy {
  z-index: 1;
  position: relative;
  margin-bottom: 2.5rem;
}

@media only screen and (min-width: 1500px) {
  .closing-statement-section .closing-statement-section__copy {
    margin-bottom: 3rem;
  }
}

@media only screen and (min-width: 2000px) {
  .closing-statement-section .closing-statement-section__copy {
    margin-bottom: 7.5rem;
  }
}

.closing-statement-section .closing-statement-section__headline {
  max-width: 35rem;
  margin-left: auto;
  margin-right: auto;
  font-family: var(--secondary-font-family);
  font-size: var(--h2);
}
@media only screen and (min-width: 768px) {
  .closing-statement-section .closing-statement-section__headline {
    font-size: var(--h1);
    line-height: var(--headline-line-height);
    max-width: 45rem;
  }
}
@media only screen and (min-width: 961px) {
  .closing-statement-section .closing-statement-section__headline {
    margin-left: auto;
    margin-right: auto;
    font-size: 2.15rem;
    line-height: 1.2;
  }
}
.closing-statement-section .closing-statement-section__body {
  max-width: 45ch;
  margin-right: auto;
  margin-left: auto;
}
@media only screen and (min-width: 768px) {
  .closing-statement-section .closing-statement-section__body {
    max-width: 35rem;
    line-height: 1.5;
  }
}
@media only screen and (min-width: 961px) {
  .closing-statement-section .closing-statement-section__body {
    max-width: 52ch;
    font-size: var(--h5);
    line-height: var(--body-line-height);
  }
}

.closing-statement__cta {
  max-width: 15rem;
  margin-left: auto;
  margin-right: auto;
}

.closing-statement-section {
  position: relative;
}

@media only screen and (min-width: 768px) {
  .closing-statement-section {
    min-height: 40rem;
  }
}
</style>
